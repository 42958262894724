
h2.projectTitle {
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  font-weight: 900 !important;
  margin-bottom: 1rem;
  margin-top: 0;
}

h4 {
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  font-weight: 900 !important;
}

h4.articleLink {
  font-size: 1rem;
  margin-top: 0;
  text-align: right;
}

div.projectInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
}

p {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.85);
}

p.date {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.95rem;
  margin-bottom: 0.25rem;
  // margin-top: 1rem;
  @media only screen and (max-width: 599px) {
    font-size: 0.9rem;
  }
}

// p.descriptionOne {
//   margin-top: 2rem;
// }

// .link {
//   font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
//   font-weight: 500 !important;
// }

.ui.loading.vertical.segment {
  height: 40vh;
}

.eight.wide.column.two.eight.wide.column.two {
  @media only screen and (max-width: 767px) {
    padding-top: 0 !important;
  }
}

a.backNavigation {
  color: #ff413c;
  font-weight: 500;
  display: flex;
  align-content: center;
  align-items: baseline;
}

.ui.button {
  color: rgba(0,0,0,.87) !important;
  background: none !important;
  border: 1px solid rgba(34,36,38,.15) !important;
}

.ui.button.back {
  color: rgba(0,0,0,.87) !important;
  background: none !important;
  border: 1px solid rgba(34,36,38,.15) !important;
  margin: 0 !important;
}

.ui.button:hover {
  background: none !important;
  color: #ff413c !important;
  border: 1px solid #ff413c !important;
}

a {
  color: #ff413c !important;
  font-weight: 600;
}
a:hover {
  color: #ff413c !important;
}

//icon

i.level.up.alternate.icon {
  // margin: 0;
  transform: rotate(90deg);
}

//list

.listContainer li {
    list-style-type: none !important;
}

.listContainer ul li:before {
    content: "\21B3 ";
    color:  rgba(0, 0, 0, 0.85);
}

.listContainer ul li {
  // display: inline-block;
  list-style-position: outside;
  list-style: disc;
}

li.no {
  display: none !important;
}

.listContainer ul {
    padding-left: 0;
}

//buttonContainer

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

//overview
.overview {
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.95rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 599px) {
    font-size: 0.9rem;
    // margin-bottom: 1rem;
  }
  

  p {
    margin: 0;
    font-family: 'IBM Plex Mono', monospace;
  }

  .overviewTitle {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    margin: 0;
    padding-right: 1rem;
    min-width:45px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.row.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  .awardText {
    max-width: 260px;
    font-size: 0.75rem;
    line-height: 1.44em;
    padding-top: 0.5rem;
    text-align: center;
    font-family: 'IBM Plex Mono', monospace;
    color: rgba(0, 0, 0, 0.5);
  }
  i.trophy {
    color: rgba(0, 0, 0, 0.5);
  }
}

img.shadow {
  box-shadow:
  0 5px 26px -8px rgb(66, 75, 80);
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

img.noShadow {
  box-shadow: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

img.borderGray {
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.tools {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.95rem;
  @media only screen and (max-width: 599px) {
    font-size: 0.9rem;
  }
  margin-top: 2rem;
  // font-size: 0.85rem;
  @media only screen and (max-width: 767px) {
    margin-top: 1rem;
  }
}

.toolsTitle {
  font-weight: 700;
}

//linkProjekt animation

.linkProject a{
  // border-bottom: 1px solid black;
  color: rgba(0, 0, 0, 0.85) !important;
  // line-height: 1rem;
  display: inline-block;
  width: auto;
  font-weight: 300;
  font-family: 'IBM Plex Mono', monospace;
  // border-radius: 17px;
  background: linear-gradient(to bottom, #ff413c 0%, #ff413c 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 0.12em 0.12em;
  transition: all 200ms;
  margin-top: 0rem;
}

.linkProject a:hover {
  color: #fff !important;
  transition: all 200ms ease-in-out;
  background-size: 100% 100%;
}

.linkExtern a{
  // border-bottom: 1px solid black;
  color: rgba(0, 0, 0, 0.85) !important;
  // line-height: 1rem;
  display: inline-block;
  width: auto;
  font-weight: 500;
  // border-radius: 17px;
  background: linear-gradient(to bottom, #ff413c 0%, #ff413c 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 0.12em 0.12em;
  transition: all 200ms;
  margin-top: 0rem;
}

.linkExtern a:hover {
  color: #fff !important;
  transition: all 200ms ease-in-out;
  background-size: 100% 100%;
}

.premiumMarker {
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  align-self: center !important;
  display: inline-block;
  font-family: var(--infographic-font-stack);
  font-weight: 900 !important;
  border: 1px solid;
  border-radius: 2ch;
  line-height: 3ex;
  padding: 0 1ch;
  font-size: 0.66em;
  text-transform: uppercase;
  margin-left: 1em;
}

// grid

// .ui.grid.projects.stackable {
//   padding-top: 2rem;
// }

// segment project

.ui.vertical.project {
  padding: 2em 0;
  padding-bottom: 3em;
  @media only screen and (max-width: 599px) {
    padding-top: 1rem;
    padding-bottom: 2em;
  }
}

.displayNone {
  display: none;
}

//text container project

.ui.text.container.project {
  max-width: 800px !important;
}

//
.ui.fluid.image.desktop {
  box-shadow:
  0 5px 26px -8px rgb(66, 75, 80);
  width: 77.5%;
  @media only screen and (max-width: 599px) { 
    width: 100%;
  }
  margin: 0rem auto;
  border-radius: 5px;

}

// mobile/desktop visible
@media only screen and (max-width: 767px) {
  .mobileHidden {
      display: none !important;
  
  }
}
@media only screen and (min-width: 768px) {
  .desktopHidden {
      display: none !important;
    
  }
}

.printInfo {
  font-size: 0.85rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}