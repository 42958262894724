.contentWrapper {
    // padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.videoBox {
    position: relative;
    overflow-y: hidden;
    // height: 550px;
    cursor: pointer;
  }
  
  .videoWrapper {
    position: relative;
    // border: 1px solid #ccc;
    box-shadow: 0px 7px 29px -8px rgba(0, 0, 0, 0.5);
    // @include sm {
    //   border: none;
    //   @include dark {
    //     box-shadow: none;
    //     border: 1px solid #ccc;
    //   }
    // }
    // @include md {
    //   background-color: #3d3e42;
    //   padding: 60px 10px 60px 10px;
    //   border-radius: 30px;
    //   border: none;
    //   @include light {
    //     // box-shadow: $boxShadow;
    //   }
    // }
  }

  
  .control {
    position: relative;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    // @include md {
    //   margin-top: -65px;
    // }
  }

  .ui.fluid.image.video {
    width: auto !important;
  }

  .videoBox {
    max-width: 350px;
    @media only screen and (max-width: 767px) { 
      max-width: 380px;
    }
    @media only screen and (max-width: 599px) { 
      max-width: 380px;
    }
  }


//   .video {
//     width: 320px;
//     height: 576px;
//     margin: 0;
//   }
  
//   .crop {
//     margin-top: -20px;
//   }